import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const testimonialData = [
    {
        image: 'michael-fagan',
        name: 'Michael Fagan',
        designation: 'Co-founder & CEO, Resonance Labs',
        description: 'Mac is not only a security architect but also a visionary who champions secure cloud transformations. His contributions elevate organizations, and his passion for excellence sets him apart.',
    },
    {
        image: 'rachael-coull',
        name: 'Rachael Coull',
        designation: 'COO & CISO @ Resonance Labs',
        description: 'Knowledgeable, enthusiastic and highly skilled - amazing at quickly understanding what is needed to deliver and exceed any objective. I can\'t recommend him enough to any organisation needing an architect with an abundance of security knowledge.',
    },
    {
        image: 'laurence-traboulsi',
        name: 'Laurence Traboulsi',
        designation: 'Senior Project Manager, Lanware',
        description: 'In each engagement he has illustrated professionalism, enthusiasm, attention to detail, team work, initiative, and he has excellent interpersonal skills. He\'s an asset to to any organisation and I look forward to working with him in the future.',
    },
    {
        image: 'will-routledge',
        name: 'Will Routledge',
        designation: 'Infrastructure Manager, Resonance Labs',
        description: 'Michael was a pleasure to work with, he is extremely helpful, knowledgeable and always over-delivers. He\'s always happy to help and support. I highly recommended him to anyone facing a daunting IT project.',
    },    
    {
        image: 'pascal-ronghi',
        name: 'Pascal Ronghi',
        designation: 'Service Delivery Manager, Lanware',
        description: 'Mac is one of the most dedicated, hardworking and knowledgeable people I\'ve ever met. I would recommend him wholeheartedly.',
    },
    {
        image: 'michael-maruszak',
        name: 'Michael Maruszak',
        designation: 'Solutions Architect, EBRD',
        description: 'Mac had a significant impact on service delivery and operations at Lanware, and his positive contributions are recognised by all in the company. He has a reputation for getting the job done right.',
    },    
    {
        image: 'maja-milosavljevic',
        name: 'Maja Milosavljevic',
        designation: 'VP & Head of Business Consulting, KAE',
        description: 'Mac is a very reliable colleague who always had a determination to resolve every problem and on a personal note he is an absolute pleasure to work with as his kind and positive character always managed to cheer up the office.',
    },
    {
        image: 'seb-payas',
        name: 'Seb Payas',
        designation: 'Service Delivery Manager, Virtuoso',
        description: 'Mac has been one of the most inspiring colleagues that I have had the pleasure of working with, this is due to his ability to open your eyes and this naturally encourages you to keep "slogging away". He is extremely technically gifted.',
    },
    {
        image: 'phillippa-birch',
        name: 'Phillippa Birch',
        designation: 'Executive Officer, DWP',
        description: 'Mac\'s thirst for knowledge and continual development should be something that everyone emulates. He has masses of energy and ideas, his mind is never quiet. Give him a problem, he\'ll give you a solution. It was a pleasure working with him.',
    },
]


const TestimonialOne = ({column , teamStyle}) => {
    return (
        <div className="row row--15">
            {testimonialData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`rn-box-card ${teamStyle}`}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`./images/testimonial/${data.image}.jpg`} alt={`${data.name} LinkedIn Profile Picture`} />
                                </figure>
                                <figcaption className="content">
                                    <p className="description">{data.description}</p>
                                    <h2 className="title">{data.name}</h2>
                                    <h6 className="subtitle theme-gradient">{data.designation}</h6>
                                </figcaption>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}

export default TestimonialOne;
