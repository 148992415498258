import React from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { SiMicrosoftazure, SiMicrosoft, SiCodereview, SiPowershell  } from "react-icons/si";
import { GiTornado, GiSecurityGate, GiConversation } from "react-icons/gi";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { BsCodeSlash } from "react-icons/bs";
import { AiFillApi } from "react-icons/ai";
import { TfiMicrosoft, TfiMicrosoftAlt } from "react-icons/tfi";
import { ImUserTie } from "react-icons/im";
import { GiCastle } from "react-icons/gi";

const ServiceList = [
    {
        icon: <SiMicrosoftazure />,
        title: 'Azure Solutions',
        description: 'I can craft Azure solutions tailored to your needs, ensuring cost-effectiveness, security, scalability, availability, and compliance.',
        color: '#0078d4'
    },
    {
        icon: <TfiMicrosoftAlt />,
        title: 'M365 &amp; Intune',
        description: 'I\'ve worked on numerous fresh M365 deployments and migrations, and configured Teams, Exchange, OneDrive, and SharePoint from scratch.',
        color: '#0FD7FF'
    },
    {
        icon: <ImUserTie />,
        title: 'vCISO Services',
        description: 'I provide SMEs with expert cyber leadership and strategies, enhancing their security posture affordably without the need for a FTE executive.',
        color: '#FF3E0F'
    },
    {
        icon: <GiCastle />,
        title: 'Security Engineering',
        description: 'PIM, PAM, SIEM, SOAR? From SOC setup to leveraging advanced E3/E5 security features, I offer design, implementation, and testing support.',
        color: '#FFBC2C'
    },
    {
        icon: <AiOutlineSafetyCertificate />,
        title: 'ISO 27001',
        description: 'I specialize in ISO 27001 implementation and audit prep, enhancing your information security management to meet global standards.',
        color: '#6CFF3A'
    },
    {
        icon: <SiPowershell />,
        title: 'PowerShell Scripting',
        description: 'Highly supportable and easy to read custom PowerShell scripts for reporting, API consumption, data enrichment, system migrations, and more.',
        color: '#4c84e5'
    },
    {
        icon: <GiTornado />,
        title: 'Disaster Recovery',
        description: "With experience in numerous DR projects, I offer insights on technical feasibility, planning, and testing. I can also implement and test your DR plan.",
        color: '#EDECF0'
    },
    {
        icon: <BsCodeSlash />,
        title: 'App or API Development',
        description: 'From web apps to and APIs, I deliver secure custom development solutions that power your digital transformation. Designs, code, and infra templates.',
        color: '#864EF4'
    },
    {
        icon: <AiFillApi />,
        title: 'Integrations',
        description: 'I\'ve lost count of the number of apps and systems I have integrated. I\'d love to help you connect your systems and automate your processes.',
        color: '#F631B7'
    }
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`} style={{paddingTop: "30px"}}>
                            <div className="" style={{color: val.color, fontSize: "50px"}}>
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;