import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            
            <li><a href="#about-me">Who's Mac?</a></li>

            <li><a href="#credentials">Credentials</a></li>

            <li><a href="#services">Services</a></li>

            <li><a href="#projects">Recent work</a></li>

            <li><a href="#clients">Clients</a></li>

            <li><a href="#reviews">Recommendations</a></li>

        </ul>
    )
}
export default Nav;
