import React, { useState } from 'react';

const BrandList = [
    {
        image: './images/clients/merlin-entertainments-logo-raw.png',
        intro: 'A global leader in location-based, family entertainment.',
        alt: 'Merlin Entertainments Logo',
        heightOverride: "160px"
    },
    {
        image: './images/clients/totalenergies.png',
        intro: 'TotalEnergies produces and markets energies on a global scale.',
        alt: 'TotalEnergies Logo',
        heightOverride: "120px"
    },
    {
        image: './images/clients/lanware-logo-optimised.png',
        intro: 'A leading IT MSP in London, exclusively serving financial services.',
        alt: 'Lanware Logo',
        heightOverride: "120px"
    },
    {
        image: './images/clients/mesmerise-logo.svg',
        intro: 'Mesmerise helps companies unlock the potential of spatial computing and AI.',
        alt: 'Mesmerise Logo',
        heightOverride: "120px"
    },
    {
        image: './images/clients/headstart-it-solutions-logo.png',
        intro: 'A highly rated MSP based in Greenwich, London.',
        alt: 'Headstart IT Solutions Logo'
    },
    {
        image: './images/clients/hub-parking.png',
        intro: 'HUB Parking is a provider of integrated, smart parking solutions.',
        alt: 'HUB Parking Technology Logo'
    },
    {
        image: './images/clients/marylebone-cricket-club-logo-raw.png',
        intro: 'Marylebone Cricket Club is the owner of Lord\'s and the guardian of the Laws of the game.',
        alt: 'Marylebone Cricket Club Logo',
        heightOverride: "120px"
    },
    {
        image: './images/clients/resonance-labs-logo-raw.png',
        intro: 'Resonance is pioneering trust, security, and transparency in AI.',
        alt: 'Resonance Labs Logo'
    }
]



const BrandOne = ({ brandStyle }) => {

    return (
        <ul className={`brand-list ${brandStyle}`} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: "center", listStyle: 'none', padding: 0 }}>
            {BrandList.map((data, index) => (
                <li key={index} style={{ border: "none", display: 'flex', justifyContent: 'center', alignItems: 'center', padding: "2.5rem", textAlign: 'center', position: 'relative' }} // Adjust flexBasis as per your layout needs
                    
                >
                    {/* Always center align the a tag */}
                    <a style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                        {/* Conditional rendering based on hover */}
                            <img
                                src={data.image}
                                alt={data.alt}
                                style={{
                                    maxHeight: data.heightOverride ? data.heightOverride : "75px",
                                    maxWidth: "100%", // Ensure it doesn't overflow the container
                                    display: 'block',
                                    marginBottom: "5rem"
                                }}
                            />
                    </a>
                </li>
            ))}
        </ul>
    );
}

export default BrandOne;