import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const CredentialList = [
    {
        image: './images/credentials/cissp-white.png',
        alt: "Certified Information System Security Professional"
    },
    {
        image: './images/credentials/chartered-manager-member-badge.png',
        alt: "CMI Chartered Manager"
    },
    {
        image: './images/credentials/azure-solutions-architect.png',
        alt: "Azure Solutions Architect Expert"
    },
    {
        image: './images/credentials/azure-cybersecurity-architect.png',
        alt: "Azure Cybersecurity Architect Expert"
    },
    {
        image: './images/credentials/azure-security-engineer-associate.png',
        alt: "Azure Security Engineer Associate"
    },
    {
        image: './images/credentials/azure-administrator-associate.png',
        alt: "Azure Administrator Associate"
    },
    {
        image: './images/credentials/security-operations-associate.png',
        alt: "Security Operations Analyst Associate"
    },
    {
        image: './images/credentials/azure-virtual-desktop-specialty.png',
        alt: "Azure Virtual Desktop Specialty"
    },
    {
        image: './images/credentials/prince2-foundation.png',
        alt: "PRINCE2 Foundation"
    },
    {
        image: './images/credentials/sdi-certified-service-desk-manager.png',
        alt: "SDI Certified Service Desk Manager"
    }
]

const CredentialsOne = ({ brandStyle }) => {
  return (
    <>
      <ul className={`brand-list ${brandStyle}`}>
        {CredentialList.map((data, index) => (
          <li key={index}>
            <img
              src={`${data.image}`}
              style={{ height: "200px", width: "auto", padding: "3rem" }}
              alt={`${data.alt}`}
            />
          </li>
        ))}
      </ul>

      <div className="rn-callto-action clltoaction-style-default style-5 mt--70" style={{paddingTop: "25px !important", paddingBottom: "25px !important"}}>
        <div className="container">
          <div className="row row--0 align-items-center content-wrapper theme-shape">
            <div className="col-12">
              <div className="inner">
                <div className="content text-center">
                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}
                  >
                    <h2 className="title" style={{marginBottom: 0}}>CISSP passed Sept. 2024! &#129395;</h2>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CredentialsOne;
