import React from 'react';

const AboutOne = () => {
    return (
        <div className="rwt-about-area rn-section-gap" id="about-me">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/mac-photo-500w.png" alt="Michael McDonald Photo" style={{borderRadius: "20px"}} />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">Hey there, I'm Mac &#128075;<br /></h2>

                                <p style={{fontSize: ""}}>Welcome! I'm a cyber and cloud consultant specialised in Azure, M365, Intune, Security Operations, and Security Engineering, including areas like SIEM, SOAR, and Attack Surface Reduction. My experience, shaped by years of working in infrastructure engineering and cybersecurity within the financial services and managed services industries, has given me the opportunity to bring practical, secure, and innovative solutions to a variety of projects.</p>

                                <p style={{fontSize: ""}}>Beyond the technical world, I've had the privilege of living in different countries and experiencing diverse cultures, which has greatly influenced my approach to both work and life. I'm passionate about aviation, enjoy traveling, mentoring, and spending time with my family and my Bernese dogs.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutOne
