import React from 'react';
import CalltoActionSeven from "../../elements/calltoaction/CalltoActionSeven";
import ScrollTop from "./ScrollTop";

const FooterTwo = () => {
    return (
        <>
            <footer className="rn-footer footer-style-default variation-two">
                <CalltoActionSeven />
            </footer>
            <ScrollTop />
        </>
    )
}

export default FooterTwo;
