import React, { useState } from "react";
import { slugify } from "../../../utils";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const BlogList = ({ data, StyleVar }) => {
  // Correct placement of useState inside the component
  const [expanded, setExpanded] = useState(data.body.map(() => false));

  // Toggle function
  const toggleExpanded = (index) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  // Function to truncate text
  const truncateText = (text, limit) => {
    if (text.length > limit) {
      return text.slice(0, limit) + "...";
    }
    return text;
  };  

  const cate = data.categories.map((value, i) => {
    return (
      <Link to={process.env.PUBLIC_URL + `/category/${slugify(value)}`} key={i}>
        {value}
        {i !== data.categories.length - 1 && ","}
      </Link>
    );
  });

  return (
    <div className={`rn-card ${StyleVar}`}>
      <div className="inner">
        <div className="content">
          <ul className="rn-meta-list">
            <li>{data.author}</li>
            <li className="separator">/</li>
            <li>{data.date}</li>
          </ul>

          <h4 className="title mb--10">{data.title}</h4>
          {data.body.map((value, i) => {
            const isExpanded = expanded[i];
            const displayText = isExpanded ? value : truncateText(value, 80);

            return (
              <div key={i}>
                <div dangerouslySetInnerHTML={{ __html: displayText }} />
                <button className="btn-default btn-small btn-border round" style={{marginTop: "-30px"}} onClick={() => toggleExpanded(i)}>
                  {isExpanded ? "Collapse" : "Expand"}
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

BlogList.propTypes = {
  data: PropTypes.object,
};

export default BlogList;
