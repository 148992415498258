import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight } from "react-icons/fi";
import { BsLinkedin, BsMicrosoftTeams } from "react-icons/bs";
import { Email } from 'react-obfuscate-email';

const callToActionData = {
    title: "Let's discuss your project!",
    subtitle: "",
    btnText: "Connect on LinkedIn",
}

const CalltoActionFive = () => {
    return (
        <div className="rn-callto-action clltoaction-style-default style-5">
            <div className="container">
                <div className="row row--0 align-items-center content-wrapper theme-shape">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div className="content text-center">
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h2 className="title">{callToActionData.title}</h2>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h6 className="subtitle">{callToActionData.subtitle}</h6>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="call-to-btn text-center">
                                        
                                        <div className="button-group">
                                            <a href="https://www.linkedin.com/in/mcdonald-mac/" target="_blank" rel="noopener noreferrer" className="btn-default btn-medium btn-icon"><BsLinkedin  fontSize={30} style={{marginRight: "1rem"}} /> {callToActionData.btnText} <i className="icon"><FiArrowRight /></i></a>
                                            <Email className={`btn-default btn-medium btn-border btn-icon`} email="mac@solvedbymac.com" subject="Contact via resolvedbymac.com">
                                                or email me
                                            </Email>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionFive;