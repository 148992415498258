import React from "react";
import Typed from "react-typed";
import SEO from "../common/SEO";
import { FiArrowRight } from "react-icons/fi";
import HeaderAAA from "../common/header/HeaderAAA";
import FooterTwo from "../common/footer/FooterTwo";
import Copyright from "../common/footer/Copyright";
import AboutOne from "../elements/about/AboutOne";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Mission from "../elements/about/Mission";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from "../data/blog/BlogList.json";
import BrandOne from "../elements/brand/BrandOne";
import TestimonialOne from "../elements/testimonial/TestimonialOne";
import ServiceOne from "../elements/service/ServiceOne";
import CredentialsOne from "../elements/credentials/CredentialsOne";
import { SiLinkedin } from "react-icons/si";
import BlogProp from "../components/blog/itemProp/BlogProp";
import { FcVoicePresentation } from "react-icons/fc";
import { MdEmail } from "react-icons/md";
import { Email } from "react-obfuscate-email";
var BlogListData = BlogClassicData.slice(0, 3);

const AAADesign = () => {
  return (
    <>
      <SEO title="Solved by Mac" />
      <main className="page-wrapper">
        <HeaderAAA btnStyle="round" HeaderSTyle="header-not-transparent" />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent height-850" id="home">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <h1 className="title theme-gradient display-two">
                    I can help with
                    <br />{" "}
                    <Typed
                      strings={[
                        "MSFT Azure",
                        "MSFT 365",
                        "InTune/MDM",
                        "vCISO services",
                        "migrations",
                        "PowerShell",
                        "zero trust",
                        "IAM systems",
                        "ISO 27001",
                        "DR planning",
                        "Group Policy",
                        "patching",
                        "vuln management",
                        "Azure desktops",
                        "GitHub Actions",
                        "containers",
                        "C# projects",
                        "JavaScript",
                        "React projects",
                        "MERN apps",
                        "SQL server",
                        "MySQL server",
                        "app packaging",
                        "policy writing",
                        "IASME CE+",
                        "SIEM &amp; SOAR",
                        "PIM &amp; PAM",
                        "IDS &amp; IPS",
                        "integrations",
                        "cloud costs",
                        "data protection",
                        "automation",
                        "API development"
                      ]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h1>
                  <p className="description">
                    <u>Updated September 2024</u>: I'm <span style={{color: '#2CFF3C'}}>available</span> for new projects
                    and invite you to contact me for a free and friendly chat!
                  </p>
                  <div className="button-group">
                    <a
                      className="btn-default btn-medium btn-icon test123"
                      href="https://www.linkedin.com/in/mcdonald-mac/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Connect on <SiLinkedin fontSize={24} style={{marginLeft: "0.25rem"}} /> LinkedIn{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </a>
                    <Email className={`btn-default btn-medium btn-border btn-icon`} email="mac@solvedbymac.com" subject="Contact via resolvedbymac.com">
                      or email me
                    </Email>
                  </div>
                  <br />
                  <p className="color-gray mt--10">No LinkedIn? Use live chat (bottom right)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <Separator />

        {/* Start About Area  */}
        <AboutOne />
        {/* End About Area  */}

        <Separator />

        {/* Start Credentials Area  */}
        <div className="rwt-brand-area rn-section-gap" id="credentials">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="yeah, I could use a little sun."
                  title="My credentials."
                  description="Hands on experience is the best teacher, but credibility helps establish trust and confidence. Here are a few of the certifications and qualifications I've picked up along the way. I usually sit an exam after I've had a chance to apply the theory in the real world, so I primarily use exams to validate new knowledge and skills. I'm always learning, so expect this list to grow!"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <CredentialsOne brandStyle="brand-style-2 variation-2" />
              </div>
            </div>
          </div>
        </div>
        {/* End Credentials Area  */}

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap" id="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="An enthusiastic 'multi-tool' for your tech ops"
                  title="My skills and services"
                  description="The list below may be exhausting, but it isn't exhaustive! I have a broad range of skills and experience, and I'm always learning (and occasionally mastering) new things! Please don't hesitate to contact me about your challenge or project - I'd love to hear how I might be of help."
                />
              </div>
            </div>
            <ServiceOne
              serviceStyle="service__style--1 bg-color-blackest radius mt--25"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap" id="projects">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="It all starts with a free conversation"
                  title="Recent projects."
                  description="I've been fortunate to work on a wide range of projects, from small scripts and server deployments, to year-long cloud migrations and the development of full-stack apps. Here are a few examples of recent work. If you'd like to know more about a specific project, please don't hesitate to ask!"
                />
                {/* Start Blog Area  */}
                <div className="rn-blog-area">
                    <div className="container">
                        <div className="row mt_dec--30">
                            <BlogProp column="col-lg-4 col-md-6 col-12 mt--30" StyleVarProp="box-card-style-default" />
                        </div>
                    </div>
                </div>
                {/* End Blog Area  */}
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        <Separator />
        {/* Start Brand Area  */}
        <div className="rwt-brand-area rn-section-gap" id="clients">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="I'm biased, but these firms are awesome"
                  title="My lovely clients."
                  description="From small local businesses to global conglomerates, I've been fortunate to work with some of the most innovative and forward-thinking companies in the world. IT managed services, artificial intelligence, mixed and augmented reality, real estate, fashion, gaming, data science, banking, insurance underwriting, asset management, and national sports - I've been around the block a few times! I'm proud to have helped them achieve their goals and solve their challenges. I'd love to help you too!"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <BrandOne brandStyle="brand-style-1" />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area  */}

        {/* Start Elements Area  */}
        <div className="rwt-elements-area rn-section-gap" id="reviews">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb--10">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="My mother said I bribed them all..."
                  title="What people say about me."
                />
                <div style={{textAlign: "center"}}>Read more at:{" "}
                  <a href="https://www.linkedin.com/in/mcdonald-mac/" target="_blank" rel="noopener noreferrer" style={{color: '#F631B7'}}>
                    linkedin.com/in/mcdonald-mac
                  </a>
                </div>
              </div>
            </div>
            <TestimonialOne
              column="col-lg-4 col-md-6 col-12"
              teamStyle="card-style-default testimonial-style-one style-two border-gradient"
            />
          </div>
        </div>
        {/* End Elements Area  */}

        {/* Start Call To Action Area  */}
        <div className="rwt-callto-action-area mb--120">
          <div className="wrapper">
            <CalltoActionFive />
          </div>
        </div>
        {/* End Call To Action Area  */}

        {/* Start Mission Area   */}
        {/* <Mission /> */}
        {/* Start Mission Area  */}

        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};

export default AAADesign;
